import { createActionGroup, props } from '@ngrx/store';
import { CommissionCycle } from 'src/app/shared/models';

export const CommissionCycleActions = createActionGroup({
  source: 'CommissionCycle',
  events: {
    'Load CommissionCycles': props<{
      from?: any;
      userId: string;
      size?: any;
      term?: string;
    }>(),
    'Load CommissionCycles Success': props<{
      commissionCycle: CommissionCycle[];
    }>(),
    'Load CommissionCycles Failure': props<{ error: any }>(),
    'Load CommissionCycle': props<{
      commissionCycleId: string;
      userId: string;
    }>(),
    'Load CommissionCycle Success': props<{
      commissionCycle: CommissionCycle;
    }>(),
    'Load CommissionCycle Failure': props<{ error: any }>(),
    'Add CommissionCycle': props<{
      userId: string;
      commissionCycle: CommissionCycle | Partial<CommissionCycle>;
    }>(),
    'Add CommissionCycle Success': props<{
      commissionCycle: CommissionCycle;
    }>(),
    'Add CommissionCycle Failure': props<{ error: any }>(),
    'Remove CommissionCycle': props<{
      commissionCycleId: string;
      userId: string;
    }>(),
    'Remove CommissionCycle Success': props<{ commissionCycleId: string }>(),
    'Remove CommissionCycle Failure': props<{ error: any }>(),
    'Update CommissionCycle': props<{
      userId: string;
      commissionCycleId: string;
      commissionCycle: Partial<CommissionCycle>;
    }>(),
    'Update CommissionCycle Success': props<{
      userId: string;
      commissionCycleId: string;
      commissionCycle: Partial<CommissionCycle>;
    }>(),
    'Update CommissionCycle Failure': props<{ error: any }>(),
  },
});
