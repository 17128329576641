import { createFeature, createReducer, on } from '@ngrx/store';
import { CommissionCycleActions } from './commission-cycle.actions';
import { CommissionCycle } from 'src/app/shared/models';

export const commissionCycleFeatureKey = 'commission-cycle';

export interface CommissionCycleState {
  commissionCycles: CommissionCycle[];
  commissionCycle: CommissionCycle | null;
  loading: boolean;
  error: any;
}

export const initialState: CommissionCycleState = {
  commissionCycles: [],
  commissionCycle: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    CommissionCycleActions.loadCommissionCycles,
    (state): CommissionCycleState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.loadCommissionCyclesSuccess,
    (state, { commissionCycle }): CommissionCycleState => ({
      ...state,
      commissionCycles: [
        ...state.commissionCycles.filter(
          x => !commissionCycle.find(y => x.id === y.id)
        ),
        ...commissionCycle,
      ],
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.loadCommissionCyclesFailure,
    (state, { error }): CommissionCycleState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CommissionCycleActions.loadCommissionCycle,
    (state): CommissionCycleState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.loadCommissionCycleSuccess,
    (state, { commissionCycle }): CommissionCycleState => ({
      ...state,
      commissionCycles: [
        ...state.commissionCycles.filter(
          item =>
            commissionCycle.id !== item.id &&
            commissionCycle.userId !== item.userId
        ),
        commissionCycle,
      ],
      commissionCycle: commissionCycle,
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.loadCommissionCycleFailure,
    (state, { error }): CommissionCycleState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CommissionCycleActions.addCommissionCycle,
    (state): CommissionCycleState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.addCommissionCycleSuccess,
    (state, { commissionCycle }): CommissionCycleState => ({
      ...state,
      commissionCycles: [...state.commissionCycles, commissionCycle],
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.addCommissionCycleFailure,
    (state, { error }): CommissionCycleState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CommissionCycleActions.removeCommissionCycle,
    (state): CommissionCycleState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.removeCommissionCycleSuccess,
    (state, { commissionCycleId }): CommissionCycleState => ({
      ...state,
      commissionCycles: state.commissionCycles.filter(
        commissionCycle => commissionCycle.id !== commissionCycleId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.removeCommissionCycleFailure,
    (state, { error }): CommissionCycleState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    CommissionCycleActions.updateCommissionCycle,
    (state): CommissionCycleState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.updateCommissionCycleSuccess,
    (state, { commissionCycle }): CommissionCycleState => ({
      ...state,
      commissionCycles: state.commissionCycles.map(item =>
        // item.id === commissionCycle.id ? commissionCycle : item
        item.id === commissionCycle.id ? { ...item, ...commissionCycle } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    CommissionCycleActions.updateCommissionCycleFailure,
    (state, { error }): CommissionCycleState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const commissionCycleFeature = createFeature({
  name: commissionCycleFeatureKey,
  reducer,
});
